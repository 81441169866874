import {
  CheckOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Spin,
  Tooltip
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from '../../../helpers/currency';
import Can from "../../../security/Can";
import BonCommandeVenteService from "../../../services/bonCommandeVente.service";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import FactureVenteService from "../../../services/factureVente.service";
import ProduitService from "../../../services/produit.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotauxFactureVente from "../../uiHelpers/Fiches/FicheTableTotauxFactureVente";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import writtenNumber from '/node_modules/written-number/lib/index';
writtenNumber.defaults.lang = 'fr';

function FactureVente() {
  const [productsForm] = Form.useForm();
  const [factureNameForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [productsModal, setProductsModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const [factureLibelleModal, setFactureLibelleModal] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [gains, setGain] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [commandeId, setCommandeId] = useState();
  const [initalQuantite, setInitalQuantite] = useState();
  const [fodec, setFodec] = useState(false);
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");


  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    acompte : 0,
    tva: 0,
    totalTimbreFiscal: "0.600",
  });

  const isVente = true;

  useEffect(() => {
    loadProduits();
    loadClients();
    loadEntreprise();
    getThemeMode();
    setUpdate();
    return () => {
      clearStates();
    };
  }, []);

  const setUpdate = async () => {
    const id = location.state?.id;
    setLoading(true);
    if (!location.state?.editMode) {
      await BonCommandeVenteService.getBonCommande(id, async (response) => {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false;
          setFodec(activeFodec);
          const res = response.data;
          res.produit_achats = response.produit_facture_ventes;
          const validReception = await loadAllBonReception(id);
          const clientId = res.bon_commande_vente_produits[0].clientId;
          const productList = { ...res };
          productList.produit_achats = productList.bon_commande_vente_produits;
          productList.date = moment(productList.date);
          productList.client = clientId;
          setProductsAndQuantites(productList, validReception);
          loadProduits();
          calculeTotalTaxes(activeFodec);          
          setSelectedCurrency(response.data.devise);
          clientChanged();
          setEditMode(true);
          generateReference();
        } else message.error("Une erreur est survenu ! ");
      });
    } else {
      await FactureVenteService.getFacture(id, async (response) => {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false;
          setFodec(activeFodec);
          const res = response.data;
          res.produit_achats = res.produit_facture_ventes;
          const validReception = await loadAllBonReception(res.devisId);
          const clientId = res.produit_achats[0].clientId;
          const productList = { ...res };
          productList.date = moment(productList.date);
          productList.client = clientId;
          setProductsAndQuantitesUpdate(productList, validReception);
          loadProduits();
          calculeTotalTaxes(activeFodec);
          clientChanged();
          setSelectedCurrency(response.data.devise);
          setUpdateId(id);
          setEditMode(true);
        } else message.error("Une erreur est survenu ! ");
      });
    }
    setLoading(false);
  };
  const setProductsAndQuantites = (productList, validReception) => {
    setCommandeId(productList.id);
    const ids = [];
    const lst = [];
    for (const element of productList.bon_commande_vente_produits) {
      ids.push(element.produitId);
      if (validReception?.length < 0) {
        element.qtotal = element.quantite;
        element.qRest = element.quantite;
        element.qLivre = 0;
        element.qtotal = element.qLivre + element.qRest;
      } else {
        element.qtotal = element.quantite;
        element.qRest = element.quantite;
        element.qLivre = 0;
        for (const reception of validReception) {
          for (const prod of reception.produit_facture_ventes) {
            if (element.produitId === prod.produitId) {
              element.qLivre += prod.quantite;
              element.qRest -= prod.quantite;
            }
          }
        }
        delete element.id;
        element.quantite = element.qRest;
      }
      if (element.qRest !== 0) lst.push(element);
    }
    setSelectedIds(ids);
    productList.produit_achats = lst;
    productsForm.setFieldsValue(productList);
  };
  const setProductsAndQuantitesUpdate = (productList, validReception) => {
    const ids = [];
    const lst = [];

    for (const element of productList.produit_achats) {
      ids.push(element.produitId);
      element.qLivre = 0;
      for (const reception of validReception) {
        for (const product of reception.produit_facture_ventes) {
          if (element.produitId === product.produitId) {
            element.qtotal = product.qtotal;
            element.qLivre += product.quantite;
            element.qRest -= element.quantite;
          }
        }
      }
      setInitalQuantite(element.quantite);
      delete element.id;
      element.qRest = element.qtotal - element.qLivre;
      if (element.qRest > 0);
      lst.push(element);
    }
    setSelectedIds(ids);
    productList.produit_achats = lst;
    productsForm.setFieldsValue(productList);
  };

  const generateReference = async () => {
    await FactureVenteService.getLast((response) => {
      if (response.status === 200 || 204) {
        if (response.data.length === 0) {
          var nbr = "1";
          var reference = "FV-" + nbr.padStart(4, "0");
        } else {
          const lastRef = response.data.reference;
          const refNbr = Number(lastRef.substring(3, 7)) + 1 + "";
          var reference = "FV-" + refNbr.padStart(4, "0");
        }
        productsForm.setFieldsValue({
          reference: reference,
          date: moment(new Date()),
        });
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const loadAllBonReception = async (id) => {
    setLoading(true);
    const response = await FactureVenteService.getActiveReception(id);
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadProduits = async (id) => {
    setLoading(true);
    const response = await ProduitService.getProduitsMainStore();
    if (response.status === 200 || 204)
      setProductList(() => {
        return formatteProductsToDataTable(response.data);
      });
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const getProducts = async (id) => {
    const response = await ProduitService.getProduitsMainStore();
    if (response.status === 200 || 204) return response.data;
    else message.error("Une erreur est survenu ! ");
  };

  const formatteProductsToDataTable = (produits) => {
    for (const produit of produits) {
      produit.showType = produit.type ? "Service" : "Produit";
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.pqte = !produit.type ? produit.stocks[0]?.quantite : "";
      produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
      produit.showFournisseur = produit.produit_achats[0]?.fournisseur?.raison_sociale ?? "Produit proprietere";
      produit.ttcVente = produit.produit_ventes[0].prixTTC;
      produit.htVente = produit.produit_ventes[0].prixHT;
    }
    return produits;
  };

  const selectProduct = (produit) => {
    setSelectedIds((prev) => [...prev, produit.id]);
    formatteProduct(produit);
  };

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;
    const prixUniteHt = produit?.produit_ventes[0]?.prixHT;
    const prixUniteTTC = produit?.produit_ventes[0]?.prixTTC;
    const tva = produit?.produit_ventes[0]?.tva;
    const ht = produit?.produit_ventes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
    const ttc = produit?.produit_ventes[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
    const remise = produit?.remise ?? 0;
    const product = {
      produitId: produit?.id,
      libelle: produit?.libelle,
      quantite: Number(qte),
      prixHT: Number(prixUniteHt),
      prixTTC: Number(prixUniteTTC),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(3),
      total_prix_TTC: Number(ttc).toFixed(3),
    };
    const productList = productsForm.getFieldValue("produit_achats")
      ? productsForm.getFieldValue("produit_achats")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produit_achats: productList });
    calculeTotalTaxes();
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };

  const priceChanged = (index) => {
    const products = productsForm.getFieldValue("produit_achats");
    const product = products[index];

    const taxe = product.tva;
    const quantite = product.quantite;
    const prixHT = Number(
      product.prixHT - product.prixHT * (product.remise / 100)
    ).toFixed(3);
    const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(3);
    const total_ht = Number(prixHT * quantite).toFixed(3);
    const total_ttc = Number(prixTTC * quantite).toFixed(3);

    products[index].total_prix_TTC = total_ttc;
    products[index].total_prix_HT = total_ht;
    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();
  };

  const calculeTotalTaxes = async (activeFodec) => {
    const isFodecActive = activeFodec ? activeFodec : fodec;
    const products = productsForm.getFieldValue("produit_achats");

    const list = [];
    const listGain = [];

    for (const product of products) {
      if (product.tva > 0.1) {
        const base = isFodecActive
          ? Number(product.total_prix_HT) +
            Number(product.total_prix_HT * 1) / 100
          : Number(product.total_prix_HT);
        const montantTaxes = isFodecActive
          ? (
              (Number(product.total_prix_HT) +
                Number(product.total_prix_HT * 0.01)) *
              (product.tva / 100)
            ).toFixed(3)
          : (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      }
      const listProd = productList.length ? productList : await getProducts();
      const prod = listProd.find((prod) => prod.id === product.produitId);
      const gain_ht = ( product.total_prix_HT - (prod?.produit_achats[0]?.prixHT * product.quantite)   ).toFixed(3);
      const gain_ttc = (product.prixTTC - prod?.produit_achats[0]?.prixTTC) * product.quantite;
      const gain = { gain_ht, gain_ttc };
      listGain.push(gain);
    }
    setGain(listGain);
    setListAppliedTaxes(list);
    calculateTotal(isFodecActive);
  };
  const clientChanged = async () => {
    const clientId = productsForm.getFieldValue("client");
    const clientList = clients.length ? clients : await getClients();
    const cli = clientList.find((client) => client.id === clientId);
    setClient(cli);
  };
  const calculateTotal = (activeFodec) => {
    const isFodecActive = activeFodec ? activeFodec : fodec;

    const factureForm = productsForm.getFieldsValue(); 
    const products = factureForm.produit_achats;
    let total_ht = 0;
    let tva = 0;
    let totalTimbreFiscal = 0.6;
    let total_ttc = 0;
    for (const product of products) {
      const montantTaxes = isFodecActive ? (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) : Number(product.total_prix_HT) * (product.tva / 100);
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
    }


    let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
    total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);

    let total_with_acompte = total_ttc;
    const acompte_montant = (( factureForm.acompte_pourcentage * total_ttc ) /100).toFixed(0);
    total_with_acompte = total_ttc - acompte_montant

    let montant_total = (Number(total_with_acompte) + Number(totalTimbreFiscal)).toFixed(3);


    const total = {
      total_ht,
      acompte_montant,
      total_with_acompte,
      tva,
      total_ttc,
      fodec: fodecMontant,
      montant_total,
      totalTimbreFiscal,
    };

    setPrixTotal(total);
    
    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);

  };

  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produit_achats")[index]?.produitId;
  };

  const addFacture = async () => {
    setLoading(true);
    setFactureLibelleModal(false);
    const product = productsForm.getFieldsValue();
    
    const libelleForm = factureNameForm.getFieldsValue();
    const reduite = libelleForm.hasOwnProperty("factureLibelle") ? true : false; 
    const factureLibelle = reduite ? libelleForm.factureLibelle : ""

    for (const element of product.produit_achats)  element.clientId = productsForm.getFieldValue("client");
    
    const factureVente = { ...product, ...prixTotal, factureLibelle , reduite };
    factureVente.etat = 0;
    factureVente.produit_facture_ventes = factureVente.produit_achats;
    delete factureVente.produit_achats;
    factureVente.bonCommandeId = commandeId;
    const updateId = location.state.id;

    const response = !location.state?.editMode
      ? await FactureVenteService.addFacture(factureVente)
      : await FactureVenteService.updateFacture(updateId, factureVente);
    if (response.status === 200 || 204) {
      location.state?.editMode
        ? message.info("Facture Mise a jour Avec Success")
        : message.info("Facture Ajouté Avec Success");
      history.push("/facture_vente");
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const getMax = (index) => {
    const rest = productsForm.getFieldValue("produit_achats")[index]?.qRest;
    const max = location.state?.editMode
      ? Number(rest) + Number(initalQuantite)
      : rest;
    return max;
  };
  const getLibelle = (index) => {
    return productsForm.getFieldValue("produit_achats")[index]?.libelle;
  };

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.valeur - b.valeur,
    },{
      title: "Quantité",
      dataIndex: "pqte",
      sorter: (a, b) => a.pqte - b.pqte,
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }

  const disableForm = () => {
    return selectedIds.length === 0;
  }

  const handelCloseQuestionModal = ( type ) => {
    setQuestionModal(false);
    if( type ){
      setFactureLibelleModal(true);
    }else{
      addFacture(); 
    }
  }

  const validForm = () => {
    if( localStorage.getItem("SHOW_FAC_MODE") === "YES" ) setQuestionModal(true) 
    else addFacture();
  }

  return (
    <>
      {Can("16_2") ? (
        <div className="px-5">
          <Spin spinning={isLoading} size="large">
            <Card className="w-100 card-shadow">
              <Form
                layout="vertical"
                form={productsForm}
                onFinish={addFacture}
              >
                <Row>
                  <FicheHeader
                    moduleName={"Facture"}
                    showClients={isVente}
                    entreprise={entreprise}
                    clients={clients}
                    fournisseurs={fournisseurs}
                    selectedClient={(client) => setClient(client)}
                  ></FicheHeader>
                </Row>
                <Row>
                  {isVente ? (
                    <Col span={8} className="px-2 pt-3">
                      <FicheClient client={client}></FicheClient>
                    </Col>
                  ) : (
                    <Col span={8} className="px-2 pt-3">
                      <FicheFournisseur
                        fournisseur={fournisseur}
                      ></FicheFournisseur>
                    </Col>
                  )}
                  <Col span={8} offset={8} className="px-2 pt-3">
                    <FicheDetails
                      currencyChanged={ ()=>  changeCurrency()} 
                    ></FicheDetails>
                  </Col>
                </Row>
                <Row>
                  <Col span="24" className="px-4">
                    <table
                      className={` mb-5 table ${
                        isDarkMode ? "table-dark" : ""
                      } `}
                    >
                      <thead className="pl-2">
                        <Row>
                          <Col span={1}>
                            <b>#</b>
                          </Col>
                          <Col span={6}>
                            <b>Libelle</b>
                          </Col>
                          <Col span={1}>
                            <Tooltip title="Quantité Total">
                              <b>QT</b>
                            </Tooltip>
                          </Col>

                          <Col span={1}>
                            <Tooltip title="Quantité Livré">
                              <b>QL</b>
                            </Tooltip>
                          </Col>

                          <Col span={1}>
                            <Tooltip title="Quantité Reste à Livré">
                              <b>Q.R.L</b>
                            </Tooltip>
                          </Col>

                          <Col span={2}>
                            <b>Quantité</b>
                          </Col>

                          <Col span={2}>
                            <Tooltip title="Prix Unitaire HT">
                              <b>Prix HT</b>
                            </Tooltip>
                          </Col>

                          <Col span={2}>
                            <b>TVA</b>
                          </Col>

                          <Col span={2}>
                            <b>Remise</b>
                          </Col>

                          <Col span={2}>
                            <b>Total HT</b>
                          </Col>

                          <Col span={2}>
                            <b>Total TTC</b>
                          </Col>

                          <Col className="px-3" span={2}>
                            <Tooltip title="Ajouter un produit">
                              <Button
                                shape="circle"
                                icon={<PlusCircleOutlined />}
                                onClick={() => setProductsModal(true)}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </thead>
                      <tbody>
                        <Form.List name="produit_achats" label="">
                          {(fields, { add, remove }) => (
                            <>
                              <Row className="pb-1 mb-1 ">
                                {fields.map(
                                  ({ key, name, ...restField }, index) => (
                                    <>
                                      <Col className="mb-0 pb-0" span={1}>
                                        {index + 1}
                                      </Col>
                                      <Col className="mb-0 pb-0" span={6}>
                                        <Text className="mb-0 pb-0">
                                          {getLibelle(index)}
                                        </Text>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "libelle"]}
                                          style={{ height: 0 }}
                                        >
                                          <Input
                                            type="hidden"
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={1}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "qtotal"]}
                                        >
                                          <InputNumber
                                            className={`${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            style={{ width: 50 }}
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={1}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "qLivre"]}
                                        >
                                          <InputNumber
                                            className={`${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            style={{ width: 50 }}
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={1}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "qRest"]}
                                        >
                                          <InputNumber
                                            className={`${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 50 }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantite"]}
                                        >
                                          <InputNumber
                                            style={{ width: 60 }}
                                            onChange={() => priceChanged(index)}
                                            className="w-125px"
                                            min={0}
                                            max={getMax(index)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "prixHT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "tva"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 60 }}
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "remise"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            min={0}
                                            max={100}
                                            style={{ width: 60 }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_HT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={` ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_TTC"]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0 px-3" span={2}>
                                        <Tooltip title="Supprimer le produit">
                                          <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                              removeElement(
                                                getProductId(index)
                                              );
                                              remove(name);
                                              calculeTotalTaxes();
                                            }}
                                          />
                                        </Tooltip>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </tbody>
                    </table>
                  </Col>
                  <Col span={8} offset={16} className="px-4">
                    <table
                      className={`table ${isDarkMode ? "table-dark" : ""} `}
                    >
                      <thead>
                        <tr>
                          <th scope="col" className="pb-3">
                            Remise :{" "}
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="remise_global"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" className="pb-3">
                            Acompte :
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="acompte_pourcentage"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                  <FicheTableGain
                   selectedCurrency={selectedCurrency}
                    gains={gains}
                    isDarkMode={isDarkMode}
                  ></FicheTableGain>
                  <FicheTableTva
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    appliedTaxes={appliedTaxes}
                  ></FicheTableTva>
                  <FicheTableTotauxFactureVente
                    fodec={fodec}
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    prixTotal={prixTotal}
                  ></FicheTableTotauxFactureVente>
                </Row>
                
                <Divider className="text-capitalize">{writtenTotal}</Divider>

                <FicheFooter entreprise={entreprise}></FicheFooter>

                <Row className="d-flex flex-row-reverse pt-5 pb-2">
                  <Button
                    className="mx-2"
                    type="primary"
                    onClick={() => validForm() }
                    disabled={disableForm()}
                  >
                    Enregistrer
                  </Button>
                  
                </Row>
              </Form>
            </Card>

            <Modal
              title="Type d'impression"
              visible={questionModal}
              onOk={()=> handelCloseQuestionModal(true)}
              onCancel={()=> handelCloseQuestionModal(false)}
              okText="OUI"
              cancelText="NON"
            >
              <p>Vous voulez genere une facture reduite ?</p>
            </Modal>

            <Modal
              visible={factureLibelleModal}
              getContainer={false}
              centered
              onOk={()=> addFacture()}
              onCancel={()=> setFactureLibelleModal(false)}
              title={"Facture reduite"}
              width={600}
              okText="Valider"
              cancelText="Annuler"
            >
              <Form layout="vertical" form={factureNameForm} onFinish={addFacture}>

                  <Form.Item
                    label="Libellé"
                    name="factureLibelle"
                    rules={[
                      {
                        required : true,
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input  size="large" />
                  </Form.Item>
              </Form>
            </Modal>


            <Modal
              visible={productsModal}
              getContainer={false}
              centered
              footer={null}
              onCancel={handleCancel}
              title={"Produits"}
              width={1000}
            >
              <Spin spinning={isLoading} size="large">
                <Card title={"Liste des produits"}>
                  <AppTable data={productList} columns={ProduitColumns} />
                </Card>
              </Spin>
            </Modal>
          </Spin>
        </div>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
}

export default FactureVente;
