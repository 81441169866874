import {
  CheckOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePptOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Image,
  Menu,
  message,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonCommandeVenteService from "../../../services/bonCommandeVente.service";
import ClientService from "../../../services/client.service";
import Devis from "../../../services/devis.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilteresWithExpand";
import felicitationImage from "../../../assets/images/felicitation.gif"
const url = environment.serverUrl;

const DashboradBonCommandeVente = () => {
  const [commandeForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [devisModal, setBonCommandeModal] = useState(false);
  const [deviss, setDevis] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();
  const [felicitation , setFelicitation] = useState(false);

  useEffect(() => {
    loadBonReception();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadBonReception = async () => {
    setLoading(true);
    await BonCommandeVenteService.getBonCommandes(async (response) => {
      if (response.status === 200 || 204) {
        await ClientService.getClientsNames((resp) => {
          if (response.status === 200 || 204) {
            initTableData(response.data, resp.data);
          } else message.error("Une erreur est survenu ! ");
        });
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const initTableData = async (data, clients) => {
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      const clientId = element.bon_commande_vente_produits[0]?.clientId;
      element.devisRef = await getCommandeReference(element.devisId);
      element.clientId = clientId;
      element.client = getClientName(clientId, clients);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    setDataTable(list);
  };

  const getCommandeReference = async (devisId) => {
    const response = await Devis.getReference(devisId);
    return response.data.reference;
  };

  const getClientName = (id, list) => {
    for (const element of list) {
      if (element.id === id) return element.nom + " " + element.prenom;
    }
  };
  const formatteEtat = (etat , pStatus , lStatus) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        if (pStatus) return "Facturé";
        if (lStatus) return "Livré";
        else return "Activé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeBonStatus = async (id, etat) => {
    const response = await BonCommandeVenteService.changeEtat(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Desactivé";
      if (etat === 1) {
        setFelicitation(true);
        setTimeout(()=> {
          setFelicitation(false);
        },3500)
      }
      message.info("Bon de commande " + msg);
    } else message.error("Une erreur est survenu ! ");
    loadBonReception();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const goToBonReception = (record) => {
    const id = record.id;
    const editMode = false;
    history.push("/v/bonCommande", { id, editMode });
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    const editMode = true;
    history.push("/v/bonCommande", { id, editMode });
  };

  const openDevisModal = async () => {
    setLoading(true);
    await Devis.getActiveDevis((response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        console.log(data);
        for (const element of data) {
          element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        }
        setDevis(response.data);
        setBonCommandeModal(true);
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const bonReceptionsCols = [
    {
      title: "Référence Bon de commande",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Référence Devis",
      dataIndex: "devisRef",
      sorter: (a, b) => a.devisRef.localeCompare(b.devisRef),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.client.localeCompare(b.client),
    },

    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a.devise.localeCompare(b.devise),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={!Can("14_4") || record.p_status || record.l_status }>
              <Menu.Item
                className="px-5"
                key="1"
                disabled={record.etat === 1}
                onClick={() => changeBonStatus(record.id, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="2"
                disabled={record.etat === -1}
                onClick={() => changeBonStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat , record.p_status , record.l_status)}
        </Dropdown.Button>
      ),
    },
    {
      title: "Action",
      key: "action",
      width:"15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("14_5")}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              disabled={record.etat === 1 || !Can("14_3")}
              shape="circle"
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference);
  };
  const downloadFile = () => {
    window.open(
      url + "Files/Vente/BonCommande/" + reference + ".pdf",
      "download"
    );
  };

  const devisCols = [
    {
      title: "Référence Devis",
      dataIndex: "reference",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Client",
      dataIndex: "showClient",
      sorter: (a, b) => a.showClient.localeCompare(b.showClient),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a.devise.localeCompare(b.devise),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => goToBonReception(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    commandeForm.resetFields();
    setBonCommandeModal(false);
  };

  return (
    <>
      {Can("14_1") ? (
        <Spin spinning={isLoading} size="large">
          <Spin spinning={felicitation} size="large" 
            indicator={ <>          
              <Image
                preview={false}
                src={felicitationImage}
                /> 
              </>}>
            <Card
              title={"Bon de commande"}
              extra={
                <Button
                  className="mx-2"
                  shape="round"
                  type="primary"
                  disabled={!Can("14_2")}
                  onClick={() => openDevisModal()}
                >
                  Ajouter Un Bon De Commande
                </Button>
              }
            >
              <TableWithFiltres  data={dataTable} columns={bonReceptionsCols} />
            </Card>
            <Modal
              title={
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="my-0">Bon de commande : {reference}</div>
                    <div className="px-5 ">
                      <Button
                        onClick={() => downloadFile()}
                        icon={<DownloadOutlined />}
                      >
                        Telecharger
                      </Button>
                    </div>
                  </div>
                </>
              }
              centered
              visible={showModal}
              footer={null}
              width={1000}
              onCancel={() => setShowModal(false)}
            >
              {showModal && (
                <div className="heigt-500px">
                  <PdfViewerModal
                    diractory="Files/Vente/BonCommande/"
                    reference={reference}
                  ></PdfViewerModal>
                </div>
              )}
            </Modal>
            <Modal
              visible={devisModal}
              getContainer={false}
              centered
              onOk={commandeForm.submit}
              onCancel={handleCancel}
              title={"Liste des devis"}
              width={800}
            >
              <Spin spinning={isLoading} size="large">
                <TableWithFiltres data={deviss} columns={devisCols} />
              </Spin>
            </Modal>
          </Spin>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboradBonCommandeVente;
