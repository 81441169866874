import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  ControlOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import country from "../../../helpers/country";
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import AppTable from "../../uiHelpers/AppTabe";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

const Client = () => {
  const [clientForm] = Form.useForm();
  const [clientModal, setClientModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [isEntrprise, setClientType] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const [advencedTabelData, setAdvencedTableData] = useState([]);
  const [simpleTable,setSimpleTable] = useState(true);

  const simpleClientColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Nom",
      dataIndex: "showNom",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone_pro",
      sorter: (a, b) => a.telephone_pro - b.telephone_pro,
    },
    {
      title: 
        <> 
          Actions
          <Tooltip title="Affichage Avancé">
            <Button
              onClick={() => clientFactures()}
              className="mx-3"
              type="dashed"
              icon={<ControlOutlined />}
            />
          </Tooltip>
        </>,
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              disabled={!Can("8_3")}
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  
  const advencedClientColums = [
    {
      title: "Type",
      dataIndex: "showType",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Nom",
      dataIndex: "showNom",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone_pro",
      sorter: (a, b) => a.telephone_pro - b.telephone_pro,
    },
    {
      title: "Rest à payé",
      dataIndex: "notPayed",
      sorter: (a, b) => a.notPayed.localeCompare(b.notPayed),
    },
    {
      title: "Paiement en cours",
      dataIndex: "enGoing",
      sorter: (a, b) => a.enGoing.localeCompare(b.enGoing),
    },
    {
      title: "Payé",
      dataIndex: "payed",
      sorter: (a, b) => a.payed.localeCompare(b.notPayed),
    },
    {
      title:<> 
        Actions
        <Tooltip title="Affichage simple">
          <Button
            onClick={() => setSimpleTable(true)}
            className="mx-3"
            type="dashed"
            icon={<ControlOutlined />}
          />
        </Tooltip>
      </>,
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              disabled
              type="dashed"
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              disabled
              shape="circle"
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadClients();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initTableData = (clients) => {
    for (const client of clients) {
      client.showType = client.type ? "Entreprise" : "Particulier";
      client.showNom = client.nom + " " + client.prenom;
    }
    setDataTable(clients);
  };

  const clientFactures = async () => {
    if( !advencedTabelData.length ){
      const list = dataTable;
      for (const element of list) {
        const response = await ClientService.getClientFacture(element.id);
        const data = response.data;
        for (const stat of data) {
          element.notPayed = stat.paiement_status === 0 ? Number(stat.total).toFixed(3) : "0.000";
          element.payed = stat.paiement_status === 1 ? Number(stat.total).toFixed(3) : "0.000";
          element.enGoing =  stat.paiement_status === 2 ? Number(stat.total).toFixed(3) : "0.000";
        }
      }
      setAdvencedTableData(list);
    }

    setSimpleTable(false);
  };
  const showClientModal = () => {
    setClientModal(true);
  };

  const handleOk = () => {
    setClientModal(false);
  };

  const updateMode = (record) => {
    setClientModal(true);
    const checkedSwitch = record.type ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    clientForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setClientModal(false);
    clearData();
  };

  const addClient = async (values) => {
    var data = values;
    data.type = data.type ? data.type : false;
    const response = editMode
      ? await ClientService.updateClient(updateId, values)
      : await ClientService.addClient(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Client ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadClients();
    setClientModal(false);
    setLoading(false);
    setSwitchValue("unchecked");
    clearData();
  };

  const clearData = () => {
    clientForm.resetFields();
    clientForm.setFieldsValue({ type: false });
    setEditMode(false);
    setUpdateId(null);
  };

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce Client ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await ClientService.deleteClient(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadClients();
    } else message.error("Une erreur est survenu ! ");
  };
  const switchChange = () => {
    setClientType(clientForm.getFieldValue("type"));
  };
  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  return (
    <>
      {Can("8_1") ? (

        <Spin spinning={isLoading} size="large">
          <Card
            title={"Clients"}
            extra={
              <Button
                className="mx-2"
                disabled={!Can("8_2")}
                shape="round"
                type="primary"
                onClick={() => showClientModal()}
              >
                Ajouter un client
              </Button>
            }
          >
            {simpleTable && simpleTable ? (
              <>
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={simpleClientColumns}
                  dataSource={dataTable}
                  pagination={{ position: ["bottomCenter"], pageSize: 5 }}
                />
              </>
            ) : (
              <>
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={advencedClientColums}
                  dataSource={advencedTabelData}
                  pagination={{ position: ["bottomCenter"], pageSize: 5 }}
                />
              </>
            )}

            <Form layout="vertical" form={clientForm} onFinish={addClient}
              initialValues={{ pays: "Tunisie", exoneration : false }}
            >
              <Modal
                visible={clientModal}
                onOk={clientForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={1100}
                title={
                  <>
                    <div className="d-flex justify-content-between ">
                      <div className="my-0">
                        {editMode ? "Modifier le client" : "Ajouter un client"}
                      </div>
                      <div>
                        <Form.Item
                          className="my-0 mx-5"
                          name="type"
                          valuePropName={switchValue}
                        >
                          <Switch
                            checkedChildren="Entreprise"
                            unCheckedChildren="Particulier"
                            className="px-4"
                            onChange={() => switchChange()}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                }
              >
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Informations Générales" key="1">
                    <Row>
                      {isEntrprise && (
                        <>
                          <Col span={8} className="px-2">
                            <Form.Item
                              label="Raison Sociale"
                              name="raison_sociale"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="px-2">
                            <Form.Item
                              label="Matricule Fiscal"
                              name="matricule_fiscal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="px-2">
                            <Form.Item
                              label="Registre Du commerce"
                              name="registre_commerce"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Nom"
                          name="nom"
                          rules={[
                            {max :100 ,message:"Maximum de caractère 100 !",},
                            {required :true , message : "Champ obligatoire !"},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Prénom"
                          name="prenom"
                          rules={[
                            {max :100 ,message:"Maximum de caractère 100 !",},
                            {required :true , message : "Champ obligatoire !"},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="E-mail"
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: 'Format invalid!',
                            }
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item 
                          rules={[
                            {
                              pattern:/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
                              message: 'Format invalid!',
                            },
                          ]}
                          label="Site Web" 
                          name="site">
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_pro"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                            },
                          ]}
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Téléphone "
                          name="telephone_pro"
                          tooltip="Maximum 13 chiffres"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 11 !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            showCount
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999999}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_per"
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Personnel"
                          name="telephone_personnel"
                          tooltip="Maximum 13 chiffres"
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999999999999}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_fax"
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Fax"
                          name="telephone_fax"
                          tooltip="Maximum 13 chiffres"
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            max={999999999999999999}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="px-3">
                        <Form.Item
                          label="Ce client est-il exonéré ?"
                          name="exoneration"
                        >
                        <Radio.Group>
                          <Radio value={false}>Non</Radio>
                          <Radio value={true}>Oui</Radio>
                        </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Adresse de facturation" key="2" forceRender={true}>
                    <b className="px-2">Adresse de facturation :</b> <br />
                    <Row>
                      <Col span={6} className="px-2 mt-2">
                        <Form.Item
                          label="Pays"
                          name="pays"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <Select size="large" >
                            {country.map((c) => (
                              <Select.Option  key={c.id} value={c.name}>
                                {c.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2 mt-2">
                        <Form.Item
                          label="Ville"
                          name="ville"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          label="Adresse"
                          name="adresse"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <b className="p-2">Adresses des livraisons :</b>
                      </Col>

                      <Form.List
                        name="adresse_clients"
                        label="List des addresse de livraisons"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <>
                                <Col span={6} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "pays"]}
                                    label="Pays"
                                    initialValues={"Tunisie"}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <Select size="large">
                                      {country.map((c) => (
                                        <Select.Option key={c.id} value={c.name}>
                                          {c.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={6} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "ville"]}
                                    label="Ville"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={11} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "adresse"]}
                                    label="Adresse"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={1} className="px-2 mt-2">
                                  <Form.Item label=" ">
                                    <MinusCircleOutlined
                                      size="large"
                                      onClick={() => remove(name)}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ))}
                            <Row>
                              <Col span={12} className="px-2 mt-3">
                                <Button type="dashed" onClick={() => add()}>
                                  Ajouter une adresse
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Modal>
            </Form>
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>

      )}

    </>
  )
};
export default Client;
