import {
  DownloadOutlined, EditOutlined, FilePptOutlined,QuestionCircleOutlined,CopyOutlined
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, message, Modal, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonLivraison from "../../../services/bonLivraison.service";
import ClientService from "../../../services/client.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
  const url = environment.serverUrl;
  
  
  const DashboardBonLivraisonSimple = () => {
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [reference,setReference] = useState();


    useEffect(() => {
        loadBonCommande();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };

    function aaazzae( i ) {


    }
    
    const loadBonCommande = async () => {
      setLoading(true);
      await BonLivraison.getBonLivraisons(false , async (response)=> {
        if (response.status === 200 || 204) {
          const data = response.data;
          await ClientService.getClientsNames( (resp) => {
            if (response.status === 200 || 204) {
              initTableData(data , resp.data)
            }else message.error("Une erreur est survenu ! ");
          });
        }else message.error("Une erreur est survenu ! ");
        setLoading(false);
      });
    };
  

    const initTableData = (data ,clients) => {
      const showMode = localStorage.getItem("SHOW_MODE");
      const list = [];
      for (const element of data) {
        element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        const clientId = element.produit_bon_livraisons[0]?.clientId;
        element.clientId = clientId;
        element.client = getClientName(clientId,clients);
        if(showMode === "NO") {
          if(element.etat !== -1) list.push(element);
        } else list.push(element);
      }
      setDataTable(list);
    };
    const getClientName = (id ,list) => {
      for (const element of list) {
        if(element.id === id ) return element.nom + " " + element.prenom;
      }
    }
  
    const formatteEtat = (etat) => {
      switch( etat ){
        case 0 : return "En cours";
        case 1 : return "Activé";
        case -1 : return "Annulé";
        default : return ""
      }
    }
  
  
    const changeDevisStatus = async (id ,etat) => {
      const response = await BonLivraison.changeEtat(id,etat);
      if (response.status === 200 || 204) {
         message.info("Bon de commande desactivé ");
      } else message.error("Une erreur est survenu ! ");
      loadBonCommande();
    }
  
    const navigate = (location) => {
      history.push(location);
    };
    
  
    const navigateToUpdate = (record) => {
      const id = record.id;
      history.push("/s/bonLivraison", {id});
    }
    const showPDFModal = (record) => {
      setShowModal(true);
      setReference(record.reference)
    } 
    const downloadFile = () => {
      window.open(url+"Files/Vente/BonLivraison/"+reference+".pdf", 'download');
    }

    const confirmDuplication = (record) => {
      Modal.confirm({
        icon: <QuestionCircleOutlined  />,
        content: "Êtes-vous sûr de dupliquer ce bon de livraison ?",
        okText: "Oui",
        onOk: () => duplicateData(record),
        cancelText: "Non",
      });
    };
    const duplicateData = async (record) => {
      const response = await BonLivraison.duplicate(record.id);
      if(response.data){
        const id  = response.data.id;
        message.success("Duplication avec success");
        history.push("/s/bonLivraison", {id});
      }else message.error("Une erreur est survenu ! ");
    }

    const BonCommandes = [
      {
        title: "Référence",
        dataIndex: "reference",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
      },
      {
        title: "Date",
        dataIndex: "showDate",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Client",
        dataIndex: "client",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      
      {
        title: "Montant TTC",
        dataIndex: "montant_total",
        render: (text, record) => <b>{Number(record.montant_total).toFixed(3)} TND</b>,
        sorter: (a, b) => a.montant_total - b.montant_total,
      },{
        title: "Devise",
        dataIndex: "devise",
        sorter: (a, b) =>a.devise.localeCompare(b.devise),
      },{
        title: "Etat",
        dataIndex: "etat",
        render: (text, record) => <Dropdown.Button overlay={
          <Menu disabled={!Can("18_4")} >
            <Menu.Item  disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeDevisStatus(record.id ,1)}>Validé</Menu.Item>
            <Menu.Item  disabled={record.etat === -1} className="px-5" key="2" onClick={()=> changeDevisStatus(record.id ,-1)}>Annulé</Menu.Item>
          </Menu>
        }>{formatteEtat(record.etat)}</Dropdown.Button>
        ,
        sorter: (a, b) => a.montant_total - b.montant_total,
      },{
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
            <Tooltip title="Visualiser">
              <Button
                disabled={!Can("18_5")}
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => showPDFModal(record)}
                icon={<FilePptOutlined />}
              />
            </Tooltip>
            <Tooltip title="Mettre à jour">
              <Button
                disabled={record.etat === 1 || !Can("18_3")}
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => navigateToUpdate(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  
    return (
      <>
      {Can("18_1") ? (

      <Spin spinning={isLoading} size="large">
        <Card
          title={"Bon de livraison simple"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("18_2")}

              onClick={() => navigate("/s/bonLivraison")}
            >
              Ajouter Un Bon De Livraison Simple
            </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={BonCommandes} />
        </Card>
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de Livraison : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
        >
          { showModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Vente/BonLivraison/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal>  
      </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
    );
  };
  export default DashboardBonLivraisonSimple;
  