import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  Input, Menu, message,
  Modal,
  Row,
  Select,
  Spin, Table, Tag,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BonCommandeVenteService from "../../../services/bonCommandeVente.service"
import EmployeService from "../../../services/employe.service";
import ProjetService from "../../../services/projet.service";
import TaskService from "../../../services/task.service";

import AppTable from "../../uiHelpers/AppTabe";
import PdfViewerModal from "../../../PdfViewerModal";
import { environment } from "../../../env/config.env";
import Can from "../../../security/Can";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

const { RangePicker } = DatePicker;
const url = environment.serverUrl;



const Projet = () => {
  const [projetForm] = Form.useForm();
  const [taskForm] = Form.useForm();

  const [projetModal, setProjetModal] = useState(false);
  const [taskModal , setTaskModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [employes , setEmployes] = useState([]);
  const [projectemployes , setProjectEmployes] = useState([]);
  const [projetId , setProjetId] = useState();
  const [commandeModal , setCommandeModal] = useState(false);
  const [commandes , setCommandes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reference,setReference] = useState();

  useEffect(() => {
    loadProjets();
    loadEmployes();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadProjets = async () => {
    setLoading(true);
    const response = await ProjetService.getProjets();
    if (response.status === 200 || 204) {
      initTableData(response.data);
    }
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (projets) => {
    for (const projet of projets) {
      projet.chlidren = projet.taches
      for (const child of projet.chlidren) {
          child.date_debut =  moment(projet.date_debut).format("YYYY/MM/DD");
          child.date_fin =  moment(projet.date_fin).format("YYYY/MM/DD");
      }
      projet.showDebutDate = moment(projet.date_debut).format("YYYY/MM/DD");
      projet.showFinDate =  moment(projet.date_fin).format("YYYY/MM/DD");
      const dates_projet = [moment(projet.date_debut), moment(projet.date_fin)];
      projet.dates_projet = dates_projet
    }
    setDataTable(projets);
  };

  const loadEmployes = async () => {
    setLoading(true);
    const response = await EmployeService.getEmployesNames();
    if (response.status === 200 || 204){
      setEmployes(response.data);
    } 
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const showProjetModal = () => {
    setProjetModal(true);
  };
  const openTaskModal = (project) => {
    const employeIds = [];
    for (const employe of project.projet_employes) {
      employeIds.push(employe.employeId);
    }
    const list = employes.filter(e => employeIds.includes(e.id));
    setProjectEmployes(list);
    setProjetId(project.id);
    taskForm.setFieldsValue({projet :project.titre });
    setTaskModal(true);
  }

  const handleOk = () => {
    setProjetModal(false);
  };

  const updateMode = (record) => {
    const projet_employes = [];
    for (const element of record.projet_employes) {
      projet_employes.push(element.employeId);
    }
    record.employes = projet_employes;
    setProjetModal(true);
    projetForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const updateTaskMode = (record) => {
    record.date_debut = moment(record.date_debut);
    record.date_fin = moment(record.date_fin);

    const projet =  dataTable.filter(p => p.id === record.projetId)[0];
    const employeIds = [];
    for (const employe of projet.projet_employes) {
      employeIds.push(employe.employeId);
    }
    const list = employes.filter(e => employeIds.includes(e.id));
    setProjectEmployes(list);
    setProjetId(projet.id);



    taskForm.setFieldsValue(record);
    taskForm.setFieldsValue({projet : projet.titre });

    setTaskModal(true);
    setUpdateId(record.id);
    setEditMode(true);

  }

  const handleCancel = () => {
    setProjetModal(false);
    clearData();
  };
  const closeTaskModal = () => {
    setTaskModal(false);
    clearData();
  }

  const addProjet = async (values) => {
    var data = values;
    data.date_debut = data.dates_projet[0];
    data.date_fin = data.dates_projet[1];
    const list = []; 
    for (const element of data.employes) {
      list.push({ employeId : element });
    }
    data.etat = 1;
    data.projet_employes = list;
    const response = editMode
      ? await ProjetService.updateProjet(updateId, values)
      : await ProjetService.addProjet(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Projet ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadProjets();
    setProjetModal(false);
    setTaskModal(false);
    setLoading(false);
    clearData();
  };

  
  const clearData = () => {
    projetForm.resetFields();
    taskForm.resetFields();
    
    setEditMode(false);
    setUpdateId(null);
  };
  


  // const checkValidDate = (status) => {
  //   const toDay = moment(new Date());
  //   if( toDay.isAfter()){
  //     return <Tag color="#f50">En cours</Tag>
  //   }else{
  //     return <Tag color="#ff2525">En retard</Tag>
      
  //   }
  // }
  
  // const getTag = (record) => {
  //   switch(record.etat){
  //     case 0 : return <Tag color="#1890ff" >Plannifier</Tag> ;
  //     case 1 : return checkValidDate(record.showFinDate);
  //     case 2 : return <Tag color="#66d132" >Termnier</Tag>;
  //     default : return "";      
  //   }
  // } 

  const addTask = async (values) => {
    var data = values;
    data.projetId = projetId
    data.etat = 1;
    const response = editMode
      ? await TaskService.updateTache(updateId, values)
      : await TaskService.addTache(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Tache ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();

  }
  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette Projet ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const confirmTaskDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette Tache ?",
      okText: "Oui",
      onOk: () => deleteTache(record),
      cancelText: "Non",
    });
  };

  const deleteItem = async (item) => {
    const response = await ProjetService.deleteProjet(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadProjets();
    } else message.error("Une erreur est survenu ! ");
  };
  
  const deleteTache = async (item) => {
    const response = await TaskService.deleteTache(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadProjets();
    } else message.error("Une erreur est survenu ! ");
  };
  const openCommandeListModal = async (projet) => {
    const response = await BonCommandeVenteService.getCommandeByProject(projet.id);
    const commandes = response.data;
    for (const element of commandes) {
      element.showDate = moment(element.date).format("YYYY/MM/DD")
    }
    setCommandes(commandes);
    setCommandeModal(true);
  }

  const getStatus = (record) => {
    if(record.etat === 3) return <Tag color="#87d068">Terminé</Tag>
    else if( record.etat === 2 ){
      if( moment(new Date()).isAfter(moment(record.date_fin)) )
        return <Tag color="#ff0000">En Retard</Tag>
      else return <Tag color="#ff7028">En Cours</Tag> 
    }else{
      if( moment(new Date()).isAfter(moment(record.date_debut)) )
        return <Tag color="#ff0000">En Retard</Tag>
      else return <Tag color="#108ee9">Planifié</Tag> 
    }
  }

  const commandeCols = [
    {
      title: "Référence Bon de commande",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button disabled
          overlay={
            <Menu></Menu>
          }
        >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Action",
      key: "action",
      width:"5%",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>

        </div>
      ),
    },
  ]
  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference)
  } 
  const downloadFile = () => {
    window.open(url+"Files/Vente/BonCommande/"+reference+".pdf", 'download');
  }

  const formatteEtat = (etat) => {
    switch (etat) {
      case 1:
        return "Planifié";
      case 2:
        return "Démarré";
      case 3:
        return "Terminé";
      default:
        return "";
    }
  };


  const changeProjetStatus = async (projetId, status) => {
    const response = await ProjetService.updateProjetStatus(projetId, status)
    if (response.status === 200 || 204) {
      // const msg = etat === 1 ? "Activé" : "desactivé" 
      //  message.info("Bon de commande " + msg );
    } else message.error("Une erreur est survenu ! ");
    await loadProjets();
  }
  const changeTaskStauts = async (taskId, status) => {
    const response = await TaskService.updateTaskStatus(taskId, status)
    if (response.status === 200 || 204) {
      // const msg = etat === 1 ? "Activé" : "desactivé" 
      //  message.info("Bon de commande " + msg );
    } else message.error("Une erreur est survenu ! ");
    await loadProjets();
  }



  const ProjetColumns = [
    {
      key : 1,
      title: "Nom du projet",
      dataIndex: "titre",
      sorter: (a, b) => a.titre.localeCompare(b.titre),
    },{
      key : 2,
      title: "Date Début",
      dataIndex: "showDebutDate",
      sorter: (a, b) => a.showDebutDate.localeCompare(b.showDebutDate),
    },
    {
      key : 3,
      title: "Date Fin",
      dataIndex: "showFinDate",
      sorter: (a, b) => a.showFinDate.localeCompare(b.showFinDate),
    },{
      title: "Etat",
      dataIndex: "etat",
      width: "10%",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={!Can("14_4")}>
              <Menu.Item 
                className="px-5" 
                key="1"
                disabled={record.etat === 3} 
                onClick={() => changeProjetStatus(record.id , 3)} 
                >
                Terminé
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="2"
                disabled={record.etat === 2}
                onClick={() => changeProjetStatus(record.id , 2)}
              >
                Démarré
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="3"
                disabled={record.etat === 1}
                onClick={() => changeProjetStatus(record.id , 1)}
              >
                Planifié
              </Menu.Item>
            </Menu>
          }
          >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (text, record) => (
        <>
        {getStatus(record)}
        
        </>
      ),
    },
    {
      title: "Action",
      key: 5,
      width:"17%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("25_3")}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Ajouter des taches">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("25_6")}

              onClick={() => openTaskModal(record)}
              icon={<DiffOutlined />}
            />
          </Tooltip>
          <Tooltip title="Liste des bons de commandes">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => openCommandeListModal(record)}
              icon={<AccountBookOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("25_7")}

              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };
  const TaskColumns = [
    {
      key : 1,
      title: "Nom de la tache",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },{
      key : 2,
      title: "Date Début",
      dataIndex: "date_debut",
      sorter: (a, b) => a.date_debut.localeCompare(b.date_debut),
    },
    {
      key : 3,
      title: "Date Fin",
      dataIndex: "date_fin",
      sorter: (a, b) => a.date_fin.localeCompare(b.date_fin),
    },{
      title: "Etat",
      dataIndex: "etat",
      width: "10%",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={!Can("14_4")}>
              <Menu.Item 
                className="px-5" 
                key="1"
                disabled={record.etat === 3} 
                onClick={() => changeTaskStauts(record.id , 3)} 
                >
                Terminé
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="2"
                disabled={record.etat === 2}
                onClick={() => changeTaskStauts(record.id , 2)}
              >
                Démarré
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="3"
                disabled={record.etat === 1}
                onClick={() => changeTaskStauts(record.id , 1)}
              >
                Planifié
              </Menu.Item>
            </Menu>
          }
          >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
    },{
      title: "Action",
      key: "5",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("25_6")}

              onClick={() => updateTaskMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("25_6")}
              onClick={() => confirmTaskDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {Can("25_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Projet"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("25_2")}
                onClick={() => showProjetModal()}
              >
                Ajouter un Projet
              </Button>
            }
          >
            <Table
              columns={ProjetColumns}
              dataSource={dataTable}
              pagination={{ position: ["bottomCenter"], pageSize: 5 }}
              rowKey={(record) => record.id}
              expandable={{
                
                rowExpandable: record => record.name !== 'Not Expandable',
                expandedRowRender: record => <>
                    <Table
                      rowKey={record.id}
                      locale={locale}
                      columns={TaskColumns}
                      dataSource={record.chlidren}
                      pagination={{ position: ["bottomCenter"], pageSize: 5 }}
                    />
                </>,
              }}
            />
            
            <Form layout="vertical" form={projetForm} onFinish={addProjet}>
              <Modal
                visible={projetModal}
                onOk={projetForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={650}
                title={editMode ? "Modifier le projet" : "Ajouter Un projet"}

              >
                <Row>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Nom du projet"
                      name="titre"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <Input size="large" type="text" maxLength={100} />
                    </Form.Item>
                  </Col>

                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Date debut et date fin"
                      name="dates_projet"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <RangePicker placeholder={["Date Début" , "Date Fin"]} format={""} size="large" className="w-100"/>
                      
                    </Form.Item>
                  </Col>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Liste des employés du projet"
                      name="employes"
                      rules={[
                        {
                          required: true,
                          message:"Champ obligatoire !",
                        },
                      ]}
                    >
                    <Select size="large"  mode="multiple" allowClear >
                        {employes.map((employe) => (
                          <Select.Option key={employe.id} value={employe.id}>
                            {employe.nom} {employe.prenom}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <TextArea rows={4} type="text" />
                    </Form.Item>
                  </Col>

                </Row>
              </Modal>
            </Form>

            <Modal
              visible={commandeModal}
              onCancel={() => setCommandeModal(false)}
              getContainer={false}
              footer={null}
              centered
              width={900}
              title={"Les commandes associées a ce projet "}
            >
              <AppTable data={commandes} columns={commandeCols} />
            </Modal>

            <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">
                    Bon de commande : {reference}
                  </div>
                  <div className="px-5 ">
                    <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                      Telecharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={()=> setShowModal(false)}
        >
          { showModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Vente/BonCommande/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
          

        </Modal>

            <Form layout="vertical" form={taskForm} onFinish={addTask}>
              <Modal
                visible={taskModal}
                onOk={taskForm.submit}
                onCancel={closeTaskModal}
                getContainer={false}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={900}
                title={editMode ? "Modifier la tache" : "Ajouter Une tache"}

              >
                <Row>
                <Col span={12} className="px-2">
                    <Form.Item
                      label="Projet"
                      name="projet"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <Input disabled size="large" type="text" maxLength={100} />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Nom de la tache"
                      name="nom"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <Input size="large" type="text" maxLength={100} />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Date debut"
                      name="date_debut"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <DatePicker placeholder="" format={""} size="large" className="w-100"/>
                      
                    </Form.Item>
                  </Col>

                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Date fin"
                      name="date_fin"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <DatePicker placeholder="" format={""} size="large" className="w-100"/>
                      
                    </Form.Item>
                  </Col>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Liste des employés"
                      name="employeId"
                      rules={[
                        {
                          required: true,
                          message:"Champ obligatoire !",
                        },
                      ]}
                    >
                    <Select size="large">
                        {projectemployes.map((employe) => (
                          <Select.Option key={employe.id} value={employe.id}>
                            {employe.nom} {employe.prenom}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <TextArea rows={4} type="text" />
                    </Form.Item>
                  </Col>

                </Row>
              </Modal>
            </Form>
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>

  );
};
export default Projet;
